import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, useHistory } from 'react-router-dom';

import {
  Button,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw'
  },
  text: {
    marginBottom: theme.spacing(3)
  }
}));

function NotFound() {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const smallDevice = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <Grid
          className={classes.text}
          container
          direction={smallDevice ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant={smallDevice ? 'h5' : 'h4'}>404</Typography>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <Typography variant={smallDevice ? 'body2' : 'h5'}>
              Não encontramos a página que você procura
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={handleClick}>
          Voltar
        </Button>
      </Grid>
    </Grid>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <Route path="*" component={NotFound} />
    </BrowserRouter>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
    document.body.appendChild(document.createElement('div'))
  );
});
